import { Link, useNavigate } from "react-router-dom"
import CardBody from "../../components/card/card-body.component"
import Card from "../../components/card/card.component"
import TemplateContent from "../../components/template-content/template-content.component"
import Page from "../../layouts/page/page.component"
import { useDispatch, useSelector } from "react-redux"
import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { getCustomer } from '../../fetch/customers.fetch'
import { removeUser } from "../../store/user/user.action"
import moment from "moment"

const CustomerTemplates = () => {
	const user = useSelector(state => state.user)
	const [ customer, setCustomer ] = useState([]);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	// ----------------------------------------------------------
	// Fetching user information
	// ----------------------------------------------------------
	const queryCustomer = useQuery({
		queryKey: ['customer', user.info.id], 
		queryFn: () => getCustomer(user.token, user.info.id),
		onSuccess: (res) => {
			if ( res.status === 401 )
				dispatch(removeUser());

			setCustomer(res.data);
		},
		onError: (error) => {
			if ( error.response.status === 401 )
				dispatch(removeUser());
			else navigate('/customers')
		}
	});

	return (
		<Page>
			<div className="row">
				<div className="col-sm-12 col-lg-12">
					<div className="row h-100 mb-5">
						{ queryCustomer.isSuccess && queryCustomer?.data?.data?.user.services.map( (service, id) => 
						service.templates.slug !== "sitel-dnevna-16-2day" && service.templates.slug !== "sitel-dnevna-16-1day" && service.templates.slug !== "sitel-vecerna-1day" && service.templates.slug !== "sitel-vecerna-2day" && (
						<Link key={id} to={service?.templates?.slug} className="col-sm-12 col-lg-3">
							<Card className={'cursor-pointer shadow-3d-primary shadow-3d-hover'}><CardBody><TemplateContent title={service?.templates?.name} description={service?.templates?.description} /></CardBody></Card>
						</Link>)
						) }

						{ user.info.first_name === 'Сител' && (
							<>
							<Link key={5} to='/templates/sitel-dnevna-16-1day' className="col-sm-12 col-lg-3 offset-lg-6">
								<Card className={'cursor-pointer shadow-3d-primary shadow-3d-hover'}><CardBody><TemplateContent title={`Сител дневна за ${moment().add(1,'days').format('dddd')} во 16ч`} description={`Темплејт за Сител дневна за ${moment().add(1,'days').format('DD.MM.Y')} во 16ч`} /></CardBody></Card>
							</Link>
							<Link key={6} to='/templates/sitel-vecerna-1day' className="col-sm-12 col-lg-3">
								<Card className={'cursor-pointer shadow-3d-primary shadow-3d-hover'}><CardBody><TemplateContent title={`Сител вечерна за ${moment().add(1,'days').format('dddd')}`} description={`Темплејт за Сител вечерна за ${moment().add(1,'days').format('DD.MM.Y')}`} /></CardBody></Card>
							</Link>

							<Link key={7} to='/templates/sitel-dnevna-16-2day' className="col-sm-12 col-lg-3 offset-lg-6">
								<Card className={'cursor-pointer shadow-3d-primary shadow-3d-hover'}><CardBody><TemplateContent title={`Сител дневна за ${moment().add(2,'days').format('dddd')} во 16ч`} description={`Темплејт за Сител дневна за ${moment().add(2,'days').format('DD.MM.Y')} во 16ч`} /></CardBody></Card>
							</Link>
							<Link key={8} to='/templates/sitel-vecerna-2day' className="col-sm-12 col-lg-3">
								<Card className={'cursor-pointer shadow-3d-primary shadow-3d-hover'}><CardBody><TemplateContent title={`Сител вечерна за ${moment().add(2,'days').format('dddd')}`} description={`Темплејт за Сител вечерна за ${moment().add(2,'days').format('DD.MM.Y')}`} /></CardBody></Card>
							</Link>
							</>
						)}
					</div>
				</div>
			</div>
		</Page>
	)
}

export default CustomerTemplates