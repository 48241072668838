import React from 'react'
import bg from './assets/clouds_bg.mp4'
import Video from '../components/video'
import { SITEL_ICONS } from '../icons/sitel-icons/conf-sitel-icons'
import { GetWeatherMaxTemp, GetWeatherMinTemp } from '../components/weather_info'

const ForecastCities = ({data}) => {

	return (
		<>
			<div className="video-bg">
				<Video src={bg} loop autoPlay muted />
			</div>

			<div className="forecast-cities">
				<div className="forecast-cities-wrap">
					<div className="city-box">
						<div className="city-name">{data['Скопје'].name}</div>
						<div className="weather-icon">
							<img src={SITEL_ICONS[103].link} width={250} height={250} alt="Icon" />
						</div>
						<div className="weather-description">слаби врнежи</div>
						<div className="temperature max_temp"><GetWeatherMaxTemp city="Скопје" day={1} /></div>
						<div className="temperature min_temp"><GetWeatherMinTemp city="Скопје" day={1} /></div>
						<div className="wind">
							<svg style={{ rotate: data['Скопје'].forecast[1].data.wind_direction+"deg" }} xmlns="http://www.w3.org/2000/svg" width="60.023" height="69.996" viewBox="0 0 60.023 69.996">
								<path id="Path_11" data-name="Path 11" d="M3.531-63.531a5.008,5.008,0,0,0-7.078,0l-25,25a5.008,5.008,0,0,0,0,7.078,5.008,5.008,0,0,0,7.078,0L-5-47.937V0A5,5,0,0,0,0,5,5,5,0,0,0,5,0V-47.937L21.469-31.469a5.008,5.008,0,0,0,7.078,0,5.008,5.008,0,0,0,0-7.078l-25-25Z" transform="translate(30.012 64.996)" fill="#fff"/>
							</svg> {parseFloat(data['Скопје'].forecast[1].data.wind_speed).toFixed()} км/ч
						</div>
					</div>

					<div className="city-box">
						<div className="city-name">{data['Велес'].name}</div>
						<div className="weather-icon">
							<img src={SITEL_ICONS[103].link} width={250} height={250} alt="Icon" />
						</div>
						<div className="weather-description">слаби врнежи</div>
						<div className="temperature max_temp">{parseFloat(data['Велес'].forecast[1].data.temp_max).toFixed()}</div>
						<div className="temperature min_temp">{parseFloat(data['Велес'].forecast[1].data.temp_min).toFixed()}</div>
						<div className="wind">
							<svg style={{ rotate: data['Велес'].forecast[1].data.wind_direction+"deg" }} xmlns="http://www.w3.org/2000/svg" width="60.023" height="69.996" viewBox="0 0 60.023 69.996">
								<path id="Path_11" data-name="Path 11" d="M3.531-63.531a5.008,5.008,0,0,0-7.078,0l-25,25a5.008,5.008,0,0,0,0,7.078,5.008,5.008,0,0,0,7.078,0L-5-47.937V0A5,5,0,0,0,0,5,5,5,0,0,0,5,0V-47.937L21.469-31.469a5.008,5.008,0,0,0,7.078,0,5.008,5.008,0,0,0,0-7.078l-25-25Z" transform="translate(30.012 64.996)" fill="#fff"/>
							</svg>
							{parseFloat(data['Велес'].forecast[1].data.wind_speed).toFixed()} км/ч
						</div>
					</div>

					<div className="city-box">
						<div className="city-name">{data['Прилеп'].name}</div>
						<div className="weather-icon">
							<img src={SITEL_ICONS[103].link} width={250} height={250} alt="Icon" />
						</div>
						<div className="weather-description">слаби врнежи</div>
						<div className="temperature max_temp">{parseFloat(data['Прилеп'].forecast[1].data.temp_max).toFixed()}</div>
						<div className="temperature min_temp">{parseFloat(data['Прилеп'].forecast[1].data.temp_min).toFixed()}</div>
						<div className="wind">
							<svg style={{ rotate: data['Прилеп'].forecast[1].data.wind_direction+"deg" }} xmlns="http://www.w3.org/2000/svg" width="60.023" height="69.996" viewBox="0 0 60.023 69.996">
								<path id="Path_11" data-name="Path 11" d="M3.531-63.531a5.008,5.008,0,0,0-7.078,0l-25,25a5.008,5.008,0,0,0,0,7.078,5.008,5.008,0,0,0,7.078,0L-5-47.937V0A5,5,0,0,0,0,5,5,5,0,0,0,5,0V-47.937L21.469-31.469a5.008,5.008,0,0,0,7.078,0,5.008,5.008,0,0,0,0-7.078l-25-25Z" transform="translate(30.012 64.996)" fill="#fff"/>
							</svg>
							{parseFloat(data['Прилеп'].forecast[1].data.wind_speed).toFixed()} км/ч
						</div>
					</div>

					<div className="city-box">
						<div className="city-name">{data['Битола'].name}</div>
						<div className="weather-icon">
							<img src={SITEL_ICONS[103].link} width={250} height={250} alt="Icon" />
						</div>
						<div className="weather-description">слаби врнежи</div>
						<div className="temperature max_temp">{parseFloat(data['Битола'].forecast[1].data.temp_max).toFixed()}</div>
						<div className="temperature min_temp">{parseFloat(data['Битола'].forecast[1].data.temp_min).toFixed()}</div>
						<div className="wind">
							<svg style={{ rotate: data['Битола'].forecast[1].data.wind_direction+"deg" }} xmlns="http://www.w3.org/2000/svg" width="60.023" height="69.996" viewBox="0 0 60.023 69.996">
								<path id="Path_11" data-name="Path 11" d="M3.531-63.531a5.008,5.008,0,0,0-7.078,0l-25,25a5.008,5.008,0,0,0,0,7.078,5.008,5.008,0,0,0,7.078,0L-5-47.937V0A5,5,0,0,0,0,5,5,5,0,0,0,5,0V-47.937L21.469-31.469a5.008,5.008,0,0,0,7.078,0,5.008,5.008,0,0,0,0-7.078l-25-25Z" transform="translate(30.012 64.996)" fill="#fff"/>
							</svg>
							{parseFloat(data['Битола'].forecast[1].data.wind_speed).toFixed()} км/ч
						</div>
					</div>

					<div className="city-box">
						<div className="city-name">{data['Охрид'].name}</div>
						<div className="weather-icon">
							<img src={SITEL_ICONS[103].link} width={250} height={250} alt="Icon" />
						</div>
						<div className="weather-description">слаби врнежи</div>
						<div className="temperature max_temp">{parseFloat(data['Охрид'].forecast[1].data.temp_max).toFixed()}</div>
						<div className="temperature min_temp">{parseFloat(data['Охрид'].forecast[1].data.temp_min).toFixed()}</div>
						<div className="wind">
							<svg style={{ rotate: data['Охрид'].forecast[1].data.wind_direction+"deg" }} xmlns="http://www.w3.org/2000/svg" width="60.023" height="69.996" viewBox="0 0 60.023 69.996">
								<path id="Path_11" data-name="Path 11" d="M3.531-63.531a5.008,5.008,0,0,0-7.078,0l-25,25a5.008,5.008,0,0,0,0,7.078,5.008,5.008,0,0,0,7.078,0L-5-47.937V0A5,5,0,0,0,0,5,5,5,0,0,0,5,0V-47.937L21.469-31.469a5.008,5.008,0,0,0,7.078,0,5.008,5.008,0,0,0,0-7.078l-25-25Z" transform="translate(30.012 64.996)" fill="#fff"/>
							</svg>
							{parseFloat(data['Охрид'].forecast[1].data.wind_speed).toFixed()} км/ч
						</div>
					</div>

					<div className="city-box">
						<div className="city-name">{data['Кавадарци'].name}</div>
						<div className="weather-icon">
							<img src={SITEL_ICONS[103].link} width={250} height={250} alt="Icon" />
						</div>
						<div className="weather-description">слаби врнежи</div>
						<div className="temperature max_temp">{parseFloat(data['Кавадарци'].forecast[1].data.temp_max).toFixed()}</div>
						<div className="temperature min_temp">{parseFloat(data['Кавадарци'].forecast[1].data.temp_min).toFixed()}</div>
						<div className="wind">
							<svg style={{ rotate: data['Кавадарци'].forecast[1].data.wind_direction+"deg" }} xmlns="http://www.w3.org/2000/svg" width="60.023" height="69.996" viewBox="0 0 60.023 69.996">
								<path id="Path_11" data-name="Path 11" d="M3.531-63.531a5.008,5.008,0,0,0-7.078,0l-25,25a5.008,5.008,0,0,0,0,7.078,5.008,5.008,0,0,0,7.078,0L-5-47.937V0A5,5,0,0,0,0,5,5,5,0,0,0,5,0V-47.937L21.469-31.469a5.008,5.008,0,0,0,7.078,0,5.008,5.008,0,0,0,0-7.078l-25-25Z" transform="translate(30.012 64.996)" fill="#fff"/>
							</svg>
							{parseFloat(data['Кавадарци'].forecast[1].data.wind_speed).toFixed()} км/ч
						</div>
					</div>

					<div className="city-box">
						<div className="city-name">{data['Струмица'].name}</div>
						<div className="weather-icon">
							<img src={SITEL_ICONS[103].link} width={250} height={250} alt="Icon" />
						</div>
						<div className="weather-description">слаби врнежи</div>
						<div className="temperature max_temp">{parseFloat(data['Струмица'].forecast[1].data.temp_max).toFixed()}</div>
						<div className="temperature min_temp">{parseFloat(data['Струмица'].forecast[1].data.temp_min).toFixed()}</div>
						<div className="wind">
							<svg style={{ rotate: data['Струмица'].forecast[1].data.wind_direction+"deg" }} xmlns="http://www.w3.org/2000/svg" width="60.023" height="69.996" viewBox="0 0 60.023 69.996">
								<path id="Path_11" data-name="Path 11" d="M3.531-63.531a5.008,5.008,0,0,0-7.078,0l-25,25a5.008,5.008,0,0,0,0,7.078,5.008,5.008,0,0,0,7.078,0L-5-47.937V0A5,5,0,0,0,0,5,5,5,0,0,0,5,0V-47.937L21.469-31.469a5.008,5.008,0,0,0,7.078,0,5.008,5.008,0,0,0,0-7.078l-25-25Z" transform="translate(30.012 64.996)" fill="#fff"/>
							</svg>
							{parseFloat(data['Струмица'].forecast[1].data.wind_speed).toFixed()} км/ч
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ForecastCities
