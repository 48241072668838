import React, { useEffect, useState } from 'react'
import Card from '../card/card.component'
import CardBody from '../card/card-body.component'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import { editCustomer } from '../../fetch/customers.fetch'
import { updateUserInfo } from '../../store/user/user.action'
import { useForm } from 'react-hook-form'
import SaveButton from '../buttons/save-button.component'
import { URL } from '../../config'
import Loader from '../loader/loader.component'

const ChangeAvatar = () => {

	const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)
	const user = useSelector( state => state.user.info )
	const token = useSelector(state => state.user.token)

	const dispatch = useDispatch();

	const { register, handleSubmit, watch, reset } = useForm();

	// ----------------------------------------------------------
	// ReactQuery Muttion (POST)
	// ----------------------------------------------------------
	const mutation = useMutation({ 
		mutationFn: (formData) => editCustomer(token, formData, user?.id),
		onSuccess: (res) => {
			dispatch(updateUserInfo(res.data.user))
			reset()
		},
	})
	
	const onSubmit = (data) => {
		const formData = new FormData();
		formData.append('avatar', data.avatar[0]);
		mutation.mutate(formData);
	};

	
	// Remove avatar
	const handleRemoveAvatar = (e) => {
		e.preventDefault();

		let data = {
			'removeAvatar': true
		}
		mutation.mutate(data);
	};


	useEffect( () => {
		const subscription = watch((value, { name, type }) => {
			if ( !value.avatar.length )
				setSaveButtonDisabled(true);
		
			if ( name === 'avatar' && value.avatar.length )
				setSaveButtonDisabled(false);
		})
		return () => subscription.unsubscribe()
	}, [watch] )

	return (
		<Card>
			<CardBody>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="col-12">
						<div className="row g-4 align-items-center">
							<div className="col-lg-auto d-flex justify-content-center">
							{
								user?.avatar ? <img className="avatar rounded-circle bg-l25-secondary w-100px h-100px" src={`${URL}/storage/uploads/${user.id}/${user?.avatar}`} alt="Avatar" width="128" height="128" /> : <div className="rounded-2 d-flex align-items-center justify-content-center bg-l10-primary w-100px h-100px"><span className="text-primary fs-1 fw-bold">{user?.first_name?.charAt(0)}</span></div>
							}
							</div>
							<div className="col-lg">
								<div className="row g-4">
									<div className="col-auto">
										<input type="file" name="avatar" onChange={() => setSaveButtonDisabled(false)} className="form-control" {...register("avatar")} />
									</div>
									{
										mutation.isLoading ? 
										<div className="col-auto">
											<Loader /> 
										</div>
										: <>
										<div className="col-auto">
											<SaveButton disabled={saveButtonDisabled} />
										</div>
										{ user.avatar ? 
										<div className="col-auto">
											<button onClick={(e) => handleRemoveAvatar(e)} className="btn btn-light-dark">
												<svg viewBox="0 0 24 24" fill="currentColor" className="svg-icon--material svg-icon btn-icon" data-name="Material--Delete"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M8 9h8v10H8z" opacity="0.3"></path><path d="M15.5 4l-1-1h-5l-1 1H5v2h14V4zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"></path></svg>
												Избриши профилна
											</button>
										</div> : '' }
										</>
									}
									<div className="col-12">
										<p className="lead text-muted">Додајте профилна слика</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</CardBody>
		</Card>
	)
}

export default ChangeAvatar