import React from 'react'
import Card from '../card/card.component'
import CardHeader from '../card/card-header.component'
import { IconReceipt2 } from '@tabler/icons-react'
import CardBody from '../card/card-body.component'
import moment from 'moment'
import { Link } from 'react-router-dom'
import TemplateContent from '../template-content/template-content.component'


const LatestTemplates = () => {
	return (
		<div class="row">
			<Link key={5} to='/templates/sitel-utrinska' className="col-sm-12 col-lg-4">
				<Card className={'cursor-pointer shadow-3d-primary shadow-3d-hover'}><CardBody><TemplateContent title={`Сител Утринска`} description={`Темплејт за Сител утринска`} /></CardBody></Card>
			</Link>

			<Link key={5} to='/templates/sitel-dnevna-12' className="col-sm-12 col-lg-4">
				<Card className={'cursor-pointer shadow-3d-primary shadow-3d-hover'}><CardBody><TemplateContent title={`Сител Дневна 12ч`} description={`Темплејт за Сител дневна во 12ч`} /></CardBody></Card>
			</Link>

			<Link key={5} to='/templates/sitel-dnevna-16' className="col-sm-12 col-lg-4">
				<Card className={'cursor-pointer shadow-3d-primary shadow-3d-hover'}><CardBody><TemplateContent title={`Сител Дневна 16ч`} description={`Темплејт за Сител утринска во 16ч`} /></CardBody></Card>
			</Link>

		</div>
	)
}

export default LatestTemplates