import { IconDiscountCheckFilled, IconMail, IconRosette, IconUser } from '@tabler/icons-react'
import React from 'react'
import { URL } from '../../config';

const UserProfile = ({user}) => {

	return (
		<div className="row g-4 py-3">
			<div className="col-12 d-flex justify-content-center">
			{
				user?.user?.avatar ? <img className="avatar rounded-circle w-100px h-100px" src={`${URL}/storage/uploads/${user?.user.id}/${user?.user?.avatar}`} alt="Avatar" width="128" height="128" /> : <div className="rounded-2 d-flex align-items-center justify-content-center btn btn-light-primary w-100px h-100px"><span className=" fs-1 fw-bold">{user?.user?.first_name?.charAt(0)}</span></div>
			}
			</div>
			<div className="col-12 d-flex justify-content-center mt-3">
				{ user?.user?.status === 1 ? <span className="text-success"><IconDiscountCheckFilled size={18} className="me-2" />Активен</span> : <span className="text-danger"><IconRosette size={18} className="me-2" />Деактивен</span> }
			</div>
			<div className="col-12">
				<div className="row g-3">
					<div className="col-12">
						<div className="d-flex align-items-center">
							<div className="flex-shrink-0">
								<IconUser size={30} color="#6c5dd3" />
							</div>
							<div className="flex-grow-1 ms-3">
								<div className="fw-bold fs-5 mb-0">{user?.user?.first_name} {user?.user?.last_name}</div>
								<div className="text-muted">Име и презиме</div>
							</div>
						</div>
					</div>

					<div className="col-12">
						<div className="d-flex align-items-center">
							<div className="flex-shrink-0">
								<IconMail size={30} color="#6c5dd3" />
							</div>
							<div className="flex-grow-1 ms-3">
								<div className="fw-bold fs-5 mb-0">{user?.user?.email}</div>
								<div className="text-muted">Е-адреса</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default UserProfile