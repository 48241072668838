import { IconArrowRight, IconCheck, IconCircleArrowUp, IconDroplet, IconPencil, IconSun, IconTemperature, IconWind, IconWindOff, IconX } from '@tabler/icons-react'
import { useMutation } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { changeCityData, editCityInfoData } from '../../../fetch/weather.fetch'
import Loader from '../../loader/loader.component'
import Select from 'react-select';
import moment from 'moment';
import { ICONS, W4ALL_ICONS, WEATHER_ICONS } from '../../../config' 
import { Link } from 'react-router-dom'
import EditButton from '../../buttons/edit-button.component'
import { useSelector } from 'react-redux'

const WeatherCityForecast = ({refetch, data}) => {
	// ----------------------------------------------------------
	// Use redux dispatch
	// ----------------------------------------------------------
	const token = useSelector(state => state.user.token)

	const [ isEditable, setIsEditable ] = useState(false)
	const [ iconChoice, setIconChoice ] = useState(data?.current?.icon);
	const [ isSearchable, setIsSearchable ] = useState(true);
	const [ isLoading, setIsLoading ] = useState(false);

	const { city, id } = data;


	// ----------------------------------------------------------
	// Form Submiting and handling
	// ----------------------------------------------------------
	const { register, getValues, setValue } = useForm({
		defaultValues: {
			temperature: data?.current?.temperature,
		}
	});

	
	// ----------------------------------------------------------
	// ReactQuery Muttion (POST)
	// ----------------------------------------------------------
	const mutation = useMutation({ 
		mutationFn: (data) => editCityInfoData( token, data ),
		onSuccess: () => {
			refetch();
			setIsEditable(false)
		}
	})

	const handleEditTemperature = () => {
		const  { temperature } = getValues()

		const editData = {
			city: id,
			icon: iconChoice,
			temperature: temperature,
		}

		// Start mutation
		mutation.mutate(editData);
	}

	// Handle Reset button for reseting the data to default values
	const handleResetButton = () => {
		setIconChoice(data?.current?.icon);
		setValue( 'temperature', data?.current?.temperature );
		setIsEditable(false);
	}


	return (
		<tr>
			<td className="fw-bold">{data.name}</td>
			<td>
				<Select
					className="basic-single"
					classNamePrefix="select"
					defaultValue={W4ALL_ICONS[iconChoice]}
					isLoading={isLoading}
					isDisabled={!isEditable}
					isClearable={false}
					isSearchable={isSearchable}
					value={W4ALL_ICONS[iconChoice]}
					options={WEATHER_ICONS}
					onChange={(choice) => setIconChoice(choice.value)}
				/>
			</td>
			<td style={{ width: 140 }}>
				<div className='fs-6 fw-bold'>
					<div className="input-group">
						{/* <IconTemperature size={16} color='#aaa' /> {data?.current?.temperature} °C */}
						<input type="text" disabled={!isEditable} className="form-control" id="temperature" {...register("temperature")} />
						<label htmlFor="temperature" className="input-group-text">°C</label>
					</div>
				</div>
			</td>
			<td className='fs-6 fw-bold'><IconWind size={16} color='#aaa' /> {data?.current?.wind_speed} km/h</td>
			<td>{ data?.current?.wind_degree ? <IconCircleArrowUp style={{ transform: `rotate(${data?.current?.wind_degree}deg)` }} /> : <IconWindOff color='#aaa' /> } </td>
			<td className='fs-6 fw-bold'><IconDroplet size={16} color='#aaa' /> {data?.current?.precipation} l/m2</td>
			<td>{moment(data?.current?.time*1000).format('lll')}</td>
			<td style={{ width: 110 }}>
			{ 
				mutation.isLoading ? 
				<Loader size="btn-sm" /> : 
				!isEditable ? <EditButton onClick={() => setIsEditable(true)} onlyIcon /> : 
				<>
					<button className="btn btn-success btn-only-icon" title="Save" onClick={handleEditTemperature}><IconCheck size={18} /></button>
					<button className="btn btn-outline-danger btn-only-icon text-danger ms-2" title="Cancel" onClick={handleResetButton}><IconX size={18} /></button>
				</> 
			}
			</td>
			<td style={{ width: '50px' }}>
				<Link to={`/weather/${data.id}`} className="btn bg-dark btn-only-icon text-white ms-2" title="View" onClick={() => setIsEditable(false)}><IconArrowRight size={20} /></Link>
			</td>
		</tr>
	)
}

export default WeatherCityForecast